import Head from 'next/head';
export default function Layout({ children }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <meta
          name="google-site-verification"
          content="jLGHUohzVujDpZVKJOgJScLlCCZMh3D-_8neGKul6IQ"
        />
        <meta
          name="description"
          content="eduline, солонгос хэл, хэлний сургалт, виз"
        />
        <meta name="title" content="Eduline LLC" />
        <meta name="category" content="education" />
        <meta name="subject" content="education" />
        <meta
          name="keywords"
          content="eduline, солонгос хэл, хэлний сургалт, виз"
        />
        <meta property="og:title" key="ogtitle" content="Eduline LLC" />
        <meta property="og:locale" key="oglocale" content="mn_MN" />
        <meta property="og:email" content="info@edulinellc.mn" />
        <meta property="og:phone_number" content="7011-0503" />
        <meta
          property="og:street-address"
          content=" Улаанбаатар, Чингэлтэй дүүрэг, 1-р хороо, Жуулчны гудамж, Дельта төв, 3 давхар – 302"
        />
        <meta property="og:locality" content="Ulaanbaatar" />
        <meta property="og:region" content="UB" />
        <meta property="og:country-name" content="Mongolia" />
        <meta
          property="og:url"
          key="ogurl"
          content="http://www.student.edulinellc.mn/"
        />
        <meta
          property="og:image"
          content="https://i1.wp.com/edulinellc.mn/wp-content/uploads/2021/12/255357892_317169146895437_7235365186256460136_n.jpg?fit=768%2C768"
        />
        <meta property="og:type" key="ogtype" content="website" />
        <meta
          property="og:description"
          key="ogdesc"
          content="eduline, солонгос хэл, хэлний сургалт, виз"
        />
        <title>Edu Line LLC</title>
      </Head>

      <main className="bg-bg">{children}</main>
    </>
  );
}
