import Check from '@components/ui/check';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';

export default function RegisterModal() {
  const [isOpen, setIsOpen] = useState(true);
  const router = useRouter();
  return (
    <div>
      <div
        className={`${
          isOpen ? 'fixed inset-0 flex items-center justify-center' : ''
        }`}
      >
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIsOpen(true)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="rounded-2xl bg-white p-4 sm:p-8 mt-24 shadow-xl">
                    <Dialog.Panel className="w-full max-w-xl transform overflow-hidden text-left transition-all">
                      <Dialog.Title
                        as="h3"
                        className="flex justify-between gap-2 text-lg font-bold leading-6 text-text md:text-xl md:font-bold"
                      >
                        <p>БҮРТГЭЛ СОНГОХ</p>
                        <button
                          className="p-2 rounded-full bg-blue-50 hover:bg-blue-100"
                          onClick={() => router.reload()}
                        >
                          <XIcon className="h-5 w-5" />
                        </button>
                      </Dialog.Title>
                      <div className="mt-4 grid sm:grid-cols-2 gap-4">
                        <div
                          onClick={() => router.push('/register')}
                          className="border group border-gray-200 rounded p-3 cursor-pointer hover:border-gray-300"
                        >
                          <div className="flex justify-center">
                            <Image
                              src="/assets/reg2.jpg"
                              width={120}
                              height={120}
                            />
                          </div>
                          <div className="flex justify-center mb-2">
                            <button
                              className="py-2 px-4 w-full bg-button group-hover:bg-hover text-white rounded flex items-center gap-1"
                              type="button"
                            >
                              <CheckIcon className="text-white h-5 w-5" />
                              Зуучлалын бүртгэл
                            </button>
                          </div>
                        </div>
                        <div
                          onClick={() => router.push('/auth/course-register')}
                          className="border group border-gray-200 rounded p-3 cursor-pointer hover:border-gray-300"
                        >
                          <div className="flex justify-center">
                            <Image
                              src="/assets/reg1.jpg"
                              width={120}
                              height={120}
                            />
                          </div>
                          <div className="flex justify-center mb-2">
                            <button
                              className="py-2 px-4 w-full bg-greenc group-hover:bg-greencHover text-white rounded flex items-center gap-1"
                              type="button"
                            >
                              <CheckIcon className="text-white h-5 w-5" />
                              Сургалтын бүртгэл
                            </button>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}
